<template>
  <div class="col-12 p-0 above fixed-bottom">
    <div class="row m-0 background-green py-3 justify-content-center">
      <BackButton @goBack="goBack" />
      <SaveButton v-if="page === totalPages" @save="save" :saveDisabled="saveDisabled" :isLoading="isLoading" />
      <NextButton v-else @goNext="goNext" :nextDisabled="nextDisabled" />
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'Recurring Donation Footer',
  components: {
    BackButton: defineAsyncComponent(() => import('./components/BackButton.vue')),
    SaveButton: defineAsyncComponent(() => import('./components/SaveButton.vue')),
    NextButton: defineAsyncComponent(() => import('./components/NextButton.vue'))
  },
  props: ['page', 'totalPages', 'nextDisabled', 'saveDisabled', 'isLoading'],
  emits: ['goBack', 'goNext', 'save'],
  methods: {
    goBack () {
      this.$emit('goBack')
    },
    goNext () {
      this.$emit('goNext')
    },
    save () {
      this.$emit('save')
    }
  }
}
</script>

<style scoped>
.above {
  z-index: inherit;
}
</style>
